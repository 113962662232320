import React from "react";
import { graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.min.css";
import "../sass/style.scss";

import { Element } from "react-scroll";
import MainLayout from "./../components/layouts/MainLayout";
import withAnalytics from "../components/hoc/withAnalytics";
import { navLinks } from "../content/nav";

import Herosection from "../components/sections/Herosection";
import About from "../components/sections/About";
import MediaCoverages from "../components/sections/MediaCoverage";
import Experiences from "../components/sections/Experiences";
import DetailedHighlights from "./../components/sections/DetailedHighlights";
import Stuff from "../components/sections/Stuff";
import Contact from "../components/sections/Contact";

import { publicationHighlights } from "../content/publicationHighlights.js";
import { entrepreneurshipHighlights } from "../content/entrepreneurshipHighlights.js";
import Seo from "../components/seo/Seo";

function Homepage({ data }) {
  return (
    <>
      <Seo seoData={data} />
      <MainLayout navLinks={navLinks}>
        <main>
          <Element name="section-home">
            <Herosection />
          </Element>
          <Element name="section-about">
            <About />
          </Element>
          <Element name="section-media-coverage">
            <MediaCoverages />
          </Element>
          <Element name="section-experiences">
            <Experiences />
          </Element>
          <Element name="section-research">
            <DetailedHighlights
              title="Research Publication Highlights"
              highlightsData={publicationHighlights}
            />
          </Element>
          <Element name="section-entrepreneurship">
            <DetailedHighlights
              title="Entrepreneurship Highlights"
              highlightsData={entrepreneurshipHighlights}
            />
          </Element>
          <Element name="section-stuff">
            <Stuff />
          </Element>
          <Element name="section-contact">
            <Contact />
          </Element>
          <div className="spacer" data-height="96"></div>
        </main>
      </MainLayout>
    </>
  );
}

export default withAnalytics(Homepage);

export const query = graphql`
  query MainPageSEOQuery {
    site {
      siteMetadata {
        additionalName
        alumniOf {
          _context
          _type
          name
          sameAs
        }
        description
        email
        jobTitle
        name
        title
        url
        sameAs
      }
    }
    file(relativePath: { eq: "mingyuan_li.png" }) {
      id
      childImageSharp {
        original {
          src
        }
      }
    }
  }
`;
