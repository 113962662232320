import React, { useContext } from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import SectionTitle from "../elements/SectionTitle";
import Skill from "../elements/Skill";
import { aboutContent, progressData, counterData } from "../../content/about";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

function About() {
  const data = useStaticQuery(graphql`
    query AboutSectionImage {
      file(relativePath: { eq: "mingyuan_li_2.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;

  return (
    <section id="about">
      <div className="container">
        <SectionTitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div
              className="text-center text-md-left rounded shadow-dark"
              style={{ overflow: "hidden" }}
            >
              <GatsbyImage
                image={data.file.childImageSharp.gatsbyImageData}
                alt={aboutContent.name}
              />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-5">
                    <a
                      href="https://blog.mingli.ca"
                      className="btn btn-default"
                      onClick={(evt) => {
                        sendEvent({
                          action: "click",
                          target: {
                            text: evt.target.innerText,
                            tagName: evt.target.tagName,
                            className: evt.target.className,
                            href: evt.target.href,
                          },
                        });
                      }}
                    >
                      Check out my blog
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-4 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
