import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";

function Timeline({ timelineItem }) {
  const { years, title, content, place, icon } = timelineItem;
  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;

  return (
    <div className="timeline-container">
      <FontAwesomeIcon icon={icon} className="timeline-icon" />
      <div className="content">
        <h3 className="title">{title}</h3>
        <p className="place" dangerouslySetInnerHTML={{ __html: place }}></p>
        <p className="time">{years}</p>

        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          onClick={(evt) => {
            sendEvent({
              action: "click",
              target: {
                text: evt.target.innerText,
                tagName: evt.target.tagName,
                className: evt.target.className,
                href: evt.target.href,
              },
            });
          }}
        />
      </div>
    </div>
  );
}

export default Timeline;
