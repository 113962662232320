import React from "react";
import { Helmet } from "react-helmet";

export default React.memo(
  ({
    name,
    additionalName,
    description,
    url,
    image,
    jobTitle,
    email,
    alumniOf,
    sameAs,
  }) => {
    const schema = {
      "@context": "https://schema.org",
      "@type": "Person",
      name: name,
      additionalName: additionalName,
      description: description,
      url: url,
      image: image,
      jobTitle: jobTitle,
      email: email,
      alumniOf: alumniOf,
      sameAs: sameAs,
    };

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schema)
            .replace(/_context/g, "@context")
            .replace(/_type/g, "@type")}
        </script>
      </Helmet>
    );
  }
);
