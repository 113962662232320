import React from "react";
import SectionTitle from "../elements/SectionTitle";
import Timeline from "../elements/Timeline";
import { educationData, experienceData } from "../../content/experience";

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <SectionTitle title="Education and Career Highlights" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} timelineItem={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} timelineItem={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
