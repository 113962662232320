import React from "react";
import SectionTitle from "../elements/SectionTitle";
import MediaCoverage from "../elements/MediaCoverage";

const mediaCoverageData = [
  {
    id: 1,
    title: "UTSC News",
    content:
      "UTSC grad named to The Next 36 looks to help others through innovation",
    link: "https://utsc.utoronto.ca/news-events/archived/utsc-grad-named-next-36-looks-help-others-through-innovation",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    title: "UTSC Commons Magazine",
    content: "Uncertainty leads to possibility",
    link:
      "https://utsc.utoronto.ca/news-events/commons-magazine/uncertainty-leads-possibility",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    title: "U of T News",
    content: "The Next 36 selects 11 U of T students and alumni",
    link:
      "https://www.utoronto.ca/news/next-36-selects-11-u-t-students-and-alumni",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function MediaCoverages() {
  return (
    <section id="media-coverage">
      <div className="container">
        <SectionTitle title="Media Coverage" />
        <div className="row fix-spacing">
          {mediaCoverageData.map((mediaCoverage) => (
            <div className="col-md-4" key={mediaCoverage.id}>
              <MediaCoverage mediaCoverage={mediaCoverage} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default MediaCoverages;
