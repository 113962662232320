import {
  faFileAlt,
  faGraduationCap,
  faHandHoldingMedical,
} from "@fortawesome/free-solid-svg-icons";

export const aboutContent = {
  name: "Ming Li",
  content:
    "I'm an ehealth professional cross-trained in research, healthcare, computing, and business - trying to help make our healthcare better.",
};

export const progressData = [
  {
    id: 1,
    title: "Research",
    percantage: 97,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Healthcare",
    percantage: 84,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Computing",
    percantage: 91,
    progressColor: "#6C6CE5",
  },
  {
    id: 4,
    title: "Business",
    percantage: 86,
    progressColor: "#1ABC9C",
  },
];

export const counterData = [
  {
    id: 1,
    title: "Journal Publications",
    count: 4,
    icon: faFileAlt,
  },
  {
    id: 2,
    title: "Google Scholar Citations",
    count: 200,
    counterUnit: "+",
    counterLink: "https://scholar.google.com/citations?user=NGQFEs4AAAAJ&hl=en",
    icon: faGraduationCap,
  },
  {
    id: 3,
    title: "Int'l Humanitarian Volunteer Trip",
    count: 1,
    icon: faHandHoldingMedical,
  },
];
