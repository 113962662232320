import React, { useContext } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";
import * as styles from "../../sass/contact.module.scss";

function Contact() {
  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;

  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className={`${styles.contactInfo} text-center`}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h2>Find something interesting?</h2>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>Let's Connect!</p>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                <p
                  onClick={(evt) => {
                    sendEvent({
                      action: "click",
                      target: {
                        text: evt.target.innerText,
                        tagName: evt.target.tagName,
                        className: evt.target.className,
                      },
                    });
                  }}
                >
                  ming@mingli.ca
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
