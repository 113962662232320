import {
  faGraduationCap,
  faQuestionCircle,
  faDraftingCompass,
  faMicroscope,
  faSeedling,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";

export const educationData = [
  {
    id: 1,
    title: "MSc ehealth",
    place: "McMaster University",
    years: "2016 - 2018",
    icon: faGraduationCap,
    content:
      '<p>Holistic studies in ehealth with courses from Health Sciences, Engineering, and MBA School.</p><p class="mt-2">Thesis: <a href="https://macsphere.mcmaster.ca/handle/11375/23755">Privacy Ontology for Health Data Sharing in Research</a></p><p class="mt-2">Awarded full scholarship, NSERC CGS-M, etc.</p>',
  },
  {
    id: 2,
    title: "HBSc Human Biology",
    place: "University of Toronto",
    years: "2009 - 2013",
    icon: faGraduationCap,
    content:
      "<p>Awarded prestigious scholarships such as Yvonne and John Chiu Award in Genetics (for highest grade in 3<sup>rd</sup> year Genetics class), University of Toronto Scholar (multi-year), NSERC USRA, etc.</p>",
  },
  {
    id: 3,
    title: "Sir John A. Macdonald CI",
    place: "President of Computer Club",
    icon: faGraduationCap,
    content:
      "Promoted interest in computer programming for beginners and taught complex algorithms to more seasoned coders.",
  },
];

export const experienceData = [
  {
    id: 1,
    title: "Mystery",
    years: "2018 - Present",
    place: "Mystery",
    icon: faQuestionCircle,
    content: "Contact me :)",
  },
  {
    id: 2,
    title: "Jr Systems Architect",
    place: "Architecture and Standards Division, eHealth Ontario",
    years: "May 2017 - Dec 2017",
    icon: faDraftingCompass,
    content:
      "Enterprise Architecture Team: Projects aimed to design and deliver integrated electronic health information systems to care settings across Ontario.",
  },
  {
    id: 3,
    title: "Student Cystic Fibrosis Research",
    place: "Gonska Lab, The Hospital for Sick Children",
    years: "May 2012 - Aug 2012; May 2013 - Feb 2015",
    icon: faMicroscope,
    content:
      "Several projects aimed to investigate membrane transport characteristics of soluble carriers in modifying the cystic fibrosis disease and to identify novel therapeutic targets for cystic fibrosis.",
  },
  {
    id: 4,
    title:
      "Natural Sciences and Engineering Research Council of Canada (NSERC) USRA Fellow",
    place:
      '<a href="https://kronzucker.org">Kronzucker Lab</a>, University of Toronto',
    years: "Feb 2011 - Apr 2013",
    icon: faSeedling,
    content:
      "Projects aimed to develop salt-tolerant strains of rice to increase our food supply in combating world hunger.",
  },
  {
    id: 5,
    title: "IT Specialist",
    years: "July 2010 - Aug 2013",
    place:
      "Biology Students’ Association, University of Toronto at Scarborough",
    icon: faLaptop,
    content:
      "Developed various software to improve workflow efficiency and effectively managed organizational change.",
  },
];
