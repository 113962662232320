import React from "react";
import SectionTitle from "./../elements/SectionTitle";
import Typewriter from "typewriter-effect";
import GraphemeSplitter from "grapheme-splitter";
import * as styles from "../../sass/stuff.module.scss";
import { stuffData } from "../../content/sutff";

const Stuff = () => {
  return (
    <>
      <section id="stuff" className="stuff">
        <div className="container">
          <SectionTitle title="Stuff" />
          <div className="row">
            <div className="col text-center">
              <span className={styles.typewriter}>
                I love{" "}
                <Typewriter
                  options={{
                    strings: stuffData,
                    autoStart: true,
                    loop: true,
                    delay: 80,
                    deleteSpeed: 40,
                    stringSplitter: (string) => {
                      return new GraphemeSplitter().splitGraphemes(string);
                    },
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stuff;
