import React from "react";
import { Helmet } from "react-helmet";
import SchemaOrg from "./SchemaOrg";

const Seo = ({ seoData }) => {
  const {
    title,
    name,
    additionalName,
    description,
    url,
    jobTitle,
    email,
    alumniOf,
    sameAs,
  } = seoData.site.siteMetadata;
  const image = `${url}${seoData.file.childImageSharp.original.src}`;

  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={url} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <SchemaOrg
        title={title}
        name={name}
        additionalName={additionalName}
        description={description}
        url={url}
        jobTitle={jobTitle}
        email={email}
        alumniOf={alumniOf}
        image={image}
        sameAs={sameAs}
      />
    </>
  );
};

export default Seo;
