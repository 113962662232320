import React from "react";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Counter({ counterItem, isVisible }) {
  const { title, count, counterUnit, counterLink, icon } = counterItem;

  return (
    <div className="fact-item">
      <div className="icon">
        <FontAwesomeIcon icon={icon} size="sm" />
      </div>
      <div className="details">
        <h3 className="mb-0 mt-0 number">
          <em className="count">
            <CountUp end={isVisible ? count : 0} />
            {counterUnit ? `${counterUnit}` : ""}
          </em>
        </h3>
        {counterLink ? (
          <a href={counterLink} style={{ color: "#5E5C7F" }}>
            <p className="mb-0">{title}</p>
          </a>
        ) : (
          <p className="mb-0">{title}</p>
        )}
      </div>
    </div>
  );
}

export default Counter;
