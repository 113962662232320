import React, { useContext } from "react";
import { AnalyticsContext } from "../../contexts/AnalyticsContext";

function MediaCoverage({ mediaCoverage }) {
  const { title, content, link, color, contentColor } = mediaCoverage;
  const { customAnalytics } = useContext(AnalyticsContext);
  const { sendEvent } = customAnalytics;

  const hexToRgb = (hex) => {
    const temp = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
    return temp.join(",");
  };

  return (
    <div
      className={
        contentColor === "light"
          ? "service-box rounded data-background padding-30 text-center text-light shadow-blue"
          : "service-box rounded data-background padding-30 text-center shadow-blue"
      }
      data-color="#6C6CE5"
      style={{
        background: color,
        boxShadow: `0px 5px 20px 0px rgba(${hexToRgb(color)}, 0.5)`,
      }}
    >
      <h3 className="mb-3 mt-0">{title}</h3>
      <p className="mb-0">{content}</p>
      <a
        href={link}
        className="btn btn-default"
        onClick={(evt) => {
          sendEvent({
            action: "click",
            target: {
              text: evt.target.innerText,
              tagName: evt.target.tagName,
              className: evt.target.className,
              href: evt.target.href,
            },
          });
        }}
      >
        View
      </a>
    </div>
  );
}

export default MediaCoverage;
